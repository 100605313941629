import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogConfig  } from '@angular/material/dialog';
import { RequestService } from '../../../services/request.service';
import { DocumentService } from '../../../services/document.service';
import { RequestDto, DocumentDto } from '../../../shared/code/InfocusInterfaces';
import { FileUploadComponent } from '../../../shared/components/file-upload/file-upload.component';
import { MatTableDataSource } from '@angular/material/table';
import { Dictionary } from '../../../shared/code/dictionary';
import { CompanyBrokerService } from 'src/app/services/companybroker.service';

@Component({
  selector: 'ecf-create',
  templateUrl: './ecf-create.component.html',
  styleUrls: ['./ecf-create.component.css']
})
export class EcfCreateComponent implements OnInit {   
  requestDto: RequestDto;  
  contactPanelState: boolean;
  claimPanelState: boolean;
  errorMessage: any;
  displayedColumns: string[];  
  dataSource = new MatTableDataSource<DocumentDto>();
  documentTypes: Dictionary;  
  showLoadedDocuments: boolean;  
  bauth:string;
  dr :string;
  cat :string;
  ecfMessageForm: FormGroup;
  isValidBroker: boolean; 

  constructor(private _formBuilder: FormBuilder,
              private dialog: MatDialog,
              private __avRoute: ActivatedRoute,
              private _router: Router,
              private _requestService: RequestService, 
              private _documentService: DocumentService,
              private _companyBrokerService: CompanyBrokerService) {
                this.contactPanelState = true;
                this.claimPanelState = true;
                this.showLoadedDocuments = false;
              }  

  ngOnInit(): void {
    this.InitializeForm();    
    this.GetDocumentTypes();
    // delete any unassigned ecf documents
    this.cleanUnassignedDocuments();
  } 

  public hasError = (controlName: string, errorName: string) =>{
    return this.ecfMessageForm.controls[controlName].hasError(errorName);
  } 

  openFileUplod(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '50vw';          
    dialogConfig.data = {
      from:'ECF',
      umr: this.requestDto.umr,
      name: this.requestDto.technicianName,
      path: this.requestDto.documentPath
    }; 

    const dialogRef = this.dialog.open(FileUploadComponent, dialogConfig );
    // subscription on close
    dialogRef.afterClosed().subscribe(() => {
      /* if(dialogRef.componentInstance.isFilesAdded){
        this.showLoadedDocuments = true; */
        this.displayedColumns = this.GetTableColumnHeadings();          
        this.getECFDocsByNewRequest();
      // }
    })  
  }

  onLoadedDocumentDelete(id){    
    this._documentService.DeleteECFDocumentById(id).subscribe((data: number) => {
      this.getECFDocsByNewRequest();          
    })
  }

  OnDocumentTypeChange(event, id){
    const formData = new FormData();
    formData.append('DocumentType', event.value);
    this._documentService.UpdateECFDocumentType(id, formData).subscribe((data: number) => {
      this.getECFDocsByNewRequest();
    })
  }

  onBackToList() {
    this._router.navigate(['/acordmessages']);
  }

  save(){
    if(!this.ecfMessageForm.valid){
      return;
    }

    this.requestDto.technicianName = this.ecfMessageForm.get('technicianName').value;    
    this.requestDto.telephone = this.ecfMessageForm.get('telephone').value.toString();
    this.requestDto.email = this.ecfMessageForm.get('email').value;
    this.requestDto.umr = this.ecfMessageForm.get('umr').value;
    this.requestDto.ucr = this.ecfMessageForm.get('ucr').value;
    this.requestDto.tr = this.ecfMessageForm.get('tr').value;
    this.requestDto.submissionType = this.ecfMessageForm.get('submissionType').value;

    this.saveECFRequest();
  }

  saveECFRequest(){    
    this._requestService.SaveECFRequest(this.requestDto).subscribe((data: number) => {
      this.getECFDocsByNewRequest();      
    })
  }

  cleanUnassignedDocuments() {
    this._documentService.DeleteUnassignedECFDocuments().subscribe((data: number) => {
      //alert(data);
    })    
  }  

  getECFDocsByNewRequest() {    
    this._documentService.GetECFDocsByNewRequest(this.requestDto.umr).subscribe((data: DocumentDto[]) => {      
      this.dataSource.data = data;      
       if(data.length > 0){
        this.showLoadedDocuments = true;        
       }
       else{
        this.showLoadedDocuments = false;
       }      
    })     
  }

  checkBrokerId() {    
    this._companyBrokerService.IsValidBroker(this.requestDto.senderIdentification).subscribe((data: boolean) => {      
      this.isValidBroker = data;      
       if(! this.isValidBroker){
        this._router.navigate(['shared/companybrokerstatus']);       
       }
    })
  }
  
  InitializeForm() { 
    this.InitializeRequestDto();

    this.ecfMessageForm = this._formBuilder.group({
      technicianName: [this.requestDto.technicianName,[Validators.required, Validators.minLength(5)]],
      telephone: [this.requestDto.telephone, [Validators.required]],
      email: [this.requestDto.email, [Validators.required, Validators.email]],
      umr: [this.requestDto.umr, [Validators.required, Validators.minLength(5)]],
      ucr: [this.requestDto.ucr,[]],
      tr: [this.requestDto.tr,[]],
      submissionType: [this.requestDto.submissionType, [Validators.required, Validators.minLength(5)]]      
      });
  }

  InitializeRequestDto(){    
    this.requestDto = this.GetDefaultRequestObject();    
    this.__avRoute.queryParams.subscribe(params => {
        this.requestDto.umr = params['umr'];
        this.requestDto.technicianName = params['techname'];
        this.requestDto.telephone = params['techtelno'];
        this.requestDto.email = params['techemail'];
        this.requestDto.tr = params['tr'];
        this.requestDto.ucr = params['ucr'];
        this.requestDto.documentPath = params['path'];
        this.requestDto.senderIdentification = params['broker'];       
      });

      this.checkBrokerId();
  }

  GetDefaultRequestObject(): RequestDto  {    
    return {
      id: 0,      
      telephone: '',
      email: '',
      documentPath: '',
      contractType: '',
      contractNature: '',
      classOfBusiness: '',
      technicianName: '',
      businessType: '',
      umr: '',
      xisTechnician: '',
      delink: '',
      submissionType: '',
      groupReference: '',
      groupNumber: '',
      simChecked: false,
      ucr: '',
      tr: '',      
      departmentType: '',
      additionalInformation: '',
      bureau: '',
      processingRequired: '',
      requestId: 0,
      creationDate: '',
      workOrderRef: '',
      slipType: '',
      documents: [],
      coverType: '',
      isSent: false,
      fullPath: '',
      documentType: '',
      accountTransactionType: '',
      senderIdentification: '',
      companyBrokerId: 0,
      createdBy: '',
      processingOption: '',
      umrDocuments: []
    };
  }

  GetDocumentTypes(): void {
    this.documentTypes =  new Dictionary([
    { key: "Agreed Wording", value: "wording_agreed"},
    { key: "Bordereau", value: "bordereau_premium"},
    { key: "Calculation", value: "calculation"},
    { key: "Endorsement", value: "lm_bureau_endorsement" },
    { key: "File Note", value: "document_file_note"},
    { key: "LPAN", value: "lm_premium_advice_note"},
    { key: "LPO208", value: "lm_lpo_208"},
    { key: "LPO301", value: "lpo301" },
    { key: "Other Document", value: "document"},
    { key: "Policy", value: "booklet_insurance_policy"},
    { key: "Premium Advice", value: "advice_premium"},
    { key: "Signed Placing Endorsement", value: "document_placing_endorsement_signed"},      
    { key: "Slip", value: "document_slip"},
    { key: "Treaty Statement", value: "document_broker_account"},
    { key: "Wording", value: "wording"},
    { key: "Wording Addenda", value: "wording_addenda"},
    { key: "XIS Correction Form", value: "form_correction"},
    { key: "XIS Policy Control Form", value: "form_policy_control"},      
    { key: "XIS Query Sheet", value: "form_query_response" }    
  ]);
}

  GetTableColumnHeadings() : string []
  {
    return ['fileName',
    'documentType',    
    'createdBy',    
    'createdOn',
    'actions'
    ];
  }
  
}
