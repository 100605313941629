export class User {
    id: string = '';
    displayName: string = '';
    principalName: string = '';
    email: string = '';
    groups: Group[] = [] ;
    appRoles: AppRole[] = []; 
  }

  export class Group {
    groupId: string = '';
    groupName: string = '';
  }

  export class AppRole {
    appRoleId: string = '';    
    appRoleName: string = '';
  }