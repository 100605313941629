<!-- <app-nav></app-nav> -->
<!-- [displayname] = displayName
[userrole] = role
[usercompany] = groups
[useremail] = email -->
<div class="bg-light sticky-top">
    <app-navigation-bar [displayname] = "displayName" [usercompany] = "groups" [useremail] = "email"

    (logoutClicked)="logoutEventHandler($event)" *ngIf="isLoggedIn()" (toggleSidenav)="sidenav.toggle()"></app-navigation-bar>
  </div>
  <mat-sidenav-container autosize *ngIf="isLoggedIn()">


    <mat-sidenav #sidenav mode="over" role="navigation"  >
      <mat-toolbar>
        <mat-toolbar-row>
          <h2 class="toolbar-title"> Main Menu </h2>
        </mat-toolbar-row>
      </mat-toolbar>

      <mat-nav-list>
        <ng-container>
          <h2 matSubheader> Main Menu </h2>
          <a mat-list-item routerLink="/" style="height: 0;"></a>
          <a mat-list-item
             (click)="sidenav.close()"
             [routerLink]="['/']"
             routerLinkActive="list-item-active">
            <mat-icon matListIcon class="nav-list-icon"> home </mat-icon>
            Home
          </a>
          <mat-divider></mat-divider>
          <a mat-list-item
             (click)="sidenav.close()"
             [routerLink]="['/requests']"
             routerLinkActive="list-item">
            <mat-icon matListIcon class="nav-list-icon"> insert_drive_file_outline</mat-icon>
            Requests
          </a>
        </ng-container>
        <ng-container>
          <h2 matSubheader> General</h2>
          <a mat-list-item
             (click)="sidenav.close()"
             [routerLink]="['/about']"
             routerLinkActive="list-item-active">
            <mat-icon matListIcon class="nav-list-icon"> business_center</mat-icon>
            About
          </a>

        </ng-container>
      </mat-nav-list>
      <span class="release">
        Release: {{version}}
      </span>
    </mat-sidenav>


<mat-sidenav-content>
  <div role="main" class="container">
    <router-outlet></router-outlet>
  </div>
</mat-sidenav-content>

  </mat-sidenav-container>

  <div  *ngIf="showLoginPage()" class="content">
    <app-loginPage (loginClicked) ='loginEventHandler($event)'></app-loginPage>
  </div>






