import { Injectable } from '@angular/core';
import { BaseService } from '../services/base.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {
  serviceURL: string;

  constructor(private baseService: BaseService) {
    this.serviceURL = environment.infocusIMRAPI;
   }

  GetECFDocsByNewRequest(umr: string) {
      return this.baseService.get(this.serviceURL + 'api/Document/GetECFDocsByNewRequest/' + umr);      
  }

  UploadECFDocuments(formData: any) {
      return this.baseService.post(this.serviceURL + 'api/Document/UploadECFDocuments', formData);
  }

  UpdateECFDocumentType(id: number, formData: any){
    return this.baseService.put(this.serviceURL + 'api/Document/UpdateECFDocumentType/' + id, formData);
  }

  DeleteECFDocument(formData: any) {
      return this.baseService.post(this.serviceURL + 'api/Document/DeleteECFDocument', formData);
  }  

  DeleteECFDocumentById(id: number) {
      return this.baseService.delete(this.serviceURL + 'api/Document/DeleteECFDocumentById/' + id);
  }

  DeleteUnassignedECFDocuments() {
    return this.baseService.delete(this.serviceURL + 'api/Document/DeleteUnassignedECFDocuments');
  }

  //#region  Submit & Resubmit Documents

  GetDocsByNewRequest(umr: string) {
    return this.baseService.get(this.serviceURL + 'api/Document/GetDocsByNewRequest/' + umr);      
}

UploadDocuments(formData: any) {
    return this.baseService.post(this.serviceURL + 'api/Document/UploadDocuments', formData);
}

UpdateDocumentType(id: number, formData: any){
  return this.baseService.put(this.serviceURL + 'api/Document/UpdateDocumentType/' + id, formData);
}

DeleteDocument(formData: any) {
    return this.baseService.post(this.serviceURL + 'api/Document/DeleteDocument', formData);
}  

DeleteDocumentById(id: number) {
    return this.baseService.delete(this.serviceURL + 'api/Document/DeleteDocumentById/' + id);
}

DeleteUnassignedDocuments() {
  return this.baseService.delete(this.serviceURL + 'api/Document/DeleteUnassignedDocuments');
}

GetAttachedRequestDocs(id: number) {
  return this.baseService.get(this.serviceURL + 'api/Document/GetAttachedRequestDocs/' + id);
}

//#endregion

//#region  UMR Documents
GetAllUMRDocs(id: number, formData: any){
    return this.baseService.put(this.serviceURL + 'api/Document/GetAllUMRDocs/' + id, formData);
  }

UpdateUMRDocStatus(id: number, formData: any){
    return this.baseService.put(this.serviceURL + 'api/Document/UpdateUMRDocStatus/' + id, formData);
  }

  
//#endregion

}

