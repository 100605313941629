<div class="jumbotron">
    <div class="card">
        <div class="card-header">
            <div class="d-flex bd-highlight">
                <div class="p-2 flex-fill bd-highlight">
                    <h3 >Messages</h3>
                </div>        
            </div>
        </div>
        <div class="card-body">
            <p *ngIf="!dataSource"><mat-spinner></mat-spinner></p>     
            <mat-form-field>
                <mat-label>Filter</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Ex. ium" #input>
            </mat-form-field>
            <mat-table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                <ng-container matColumnDef="submissionType">
                    <mat-header-cell *matHeaderCellDef> Submission<br/>Type </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.submissionType}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="umr">
                    <mat-header-cell *matHeaderCellDef> UMR </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.umr}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="ucr">
                    <mat-header-cell *matHeaderCellDef> UCR </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.ucr}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="tr">
                    <mat-header-cell *matHeaderCellDef> TR </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.tr}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="sentDate">
                    <mat-header-cell *matHeaderCellDef> Date<br/>Sent </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.sentDate | date:'dd/MM/yyyy HH:mm:ss'}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="technicianName">
                    <mat-header-cell *matHeaderCellDef> Technician<br/>Name </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.technicianName}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="createdBy">
                    <mat-header-cell *matHeaderCellDef> Created<br/>By </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.createdBy}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="operation">
                    <mat-header-cell *matHeaderCellDef> Operation </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.requestSubmitType}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="status">
                    <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.messageStatus}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="tStatus">
                    <mat-header-cell *matHeaderCellDef> Technical<br/>Status </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.technicalStatus}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="workOrderReference">
                    <mat-header-cell *matHeaderCellDef> Reference </mat-header-cell>
                    <mat-cell *matCellDef="let element"> {{element.workOrderReference}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef></mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <a [routerLink]="['/acordmessages/acordmessagedetail/', element.id]">
                                            <mat-icon mat-list-icon matTooltip="View Submission Details">zoom_in</mat-icon>
                                        </a>
                                    </td>
                                    <td>
                                        <a [routerLink]="['/requests/requestdetail/', element.requestId]">
                                            <mat-icon mat-list-icon matTooltip="View Details">info</mat-icon>
                                        </a>
                                    </td>
                                    <td *ngIf="!element.isCancelled && element.workOrderReference && !element.isCancellation">
                                        <a (click)="onDelete(element.id)">
                                            <mat-icon mat-list-icon matTooltip="Cancel Work Order">delete</mat-icon>
                                        </a>
                                    </td>
                                    <td *ngIf="element.workOrderReference && !element.isCancellation">
                                        <a [routerLink]="['/acordmessages/acordmessageresubmit/', element.id]">
                                            <mat-icon mat-list-icon matTooltip="Resubmit Work Order">refresh</mat-icon>
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </mat-cell>
                </ng-container>        
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </mat-table>
            <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
        </div>
    </div>
</div>
