import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { RequestService } from  '../../../services/request.service';
import { RequestDto } from '../../../shared/code/InfocusInterfaces';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'requests',
  templateUrl: './requests.component.html',
  styleUrls: ['./requests.component.css']
})
export class RequestsComponent implements OnInit, AfterViewInit {
  displayedColumns: string[];
  dataSource = new MatTableDataSource<RequestDto>();

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private _requestService: RequestService, 
              private _avRoute : ActivatedRoute) {
    // this.getRequestsByUser();
    this.displayedColumns = this.GetTableColumnHeadings();
   }

  ngOnInit(): void {
    setTimeout(() => {
   this.getRequestsByUser();
      
    }, 1000);
   this.displayedColumns = this.GetTableColumnHeadings();

   this._avRoute.queryParams.subscribe(params => {
    let productid = params['productid'];
    let color = params['color'];
    
    // console.log(productid);// OUTPUT 1534
    // console.log(color);// OUTPUT red
  });
    
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  getRequestsByUser() {
   this._requestService.getRequestsByUser().subscribe((data: RequestDto[]) => {
     this.dataSource.data = data;
   })   
  }
    
  GetTableColumnHeadings() : string []
  {
    return [
    'submissionType',
    'umr',
    'ucr',
    'tr',
    'telephone',
    'email',
    'creationDate',
    'technicianName',
    'classOfBusiness',
    'actions'
    ];
  }
}