import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService, AuthInfo } from '../shared/Authentication/auth.service';

@Injectable({
  providedIn: 'root'
})

export class BaseService { 
  mainAppURL: string;  
  
  authInfo = new AuthInfo();

  constructor(private httpclient: HttpClient, private authService: AuthService) {
      //this.mainAppURL = baseUrl;
      this.authService.authEmitter.subscribe(ai => this.authInfo = ai);
  }
  
  // GET
  get<T>(path:string): Observable<T> {
    if(this.authInfo.httpOptions){
      return this.httpclient.get<T>(path, <Object>this.authInfo.httpOptions);
   }
  }  

  // POST
  post<T>(path:string, body:any): Observable<T> {
    if(this.authInfo.httpOptions){
      return this.httpclient.post<T>(path, body, <Object>this.authInfo.httpOptions);              
    }
  } 

  // PUT
  put<T>(path:string, body:any): Observable<T> {
    if(this.authInfo.httpOptions){
      return this.httpclient.put<T>(path, body, <Object>this.authInfo.httpOptions);              
    }
  } 

  // DELETE
  delete<T>(path:string): Observable<T> {
    if(this.authInfo.httpOptions){
      return this.httpclient.delete<T>(path, <Object>this.authInfo.httpOptions);
    }
  }
}
