import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { HomeComponent } from './shared/components/home/home.component';
import { AboutComponent } from './shared/components/about/about.component';
import { ContactComponent } from './shared/components/contact/contact.component';
import { FileUploadComponent } from './shared/components/file-upload/file-upload.component';
import { CompanyBrokerStatusComponent } from './shared/components/company-broker-status/company-broker-status.component';
import { AcordMessagesComponent } from './message-management/components/acord-messages/acord-messages.component';
import { AcordMessageDetailComponent } from './message-management/components/acord-messages/acord-message-detail/acord-message-detail.component';
import { AcordMessageSubmitComponent } from './message-management/components/acord-messages/acord-message-submit/acord-message-submit.component';
import { AcordMessageResubmitComponent } from './message-management/components/acord-messages/acord-message-resubmit/acord-message-resubmit.component';
import { RequestsComponent } from './message-management/components/requests/requests.component';
import { RequestDetailComponent } from './message-management/components/requests/request-detail/request-detail.component';
import { EcfCreateComponent } from './message-management/components/ecf-create/ecf-create.component';

const routes: Routes = [
  { path: 'about', component: AboutComponent},
  { path: 'contact', component: ContactComponent},
  { path: 'acordmessages', component: AcordMessagesComponent},
  { path: 'acordmessages/acordmessagedetail/:id', component: AcordMessageDetailComponent},
  { path: 'acordmessages/acordmessagesubmit', component: AcordMessageSubmitComponent},
  { path: 'acordmessages/acordmessageresubmit/:id', component: AcordMessageResubmitComponent},
  { path: 'requests', component: RequestsComponent},
  { path: 'requests/requestdetail/:id', component: RequestDetailComponent},
  { path: 'ecfcreate', component: EcfCreateComponent},
  { path: 'shared/fileupload', component: FileUploadComponent},
  { path: 'shared/companybrokerstatus', component: CompanyBrokerStatusComponent},
  { path: '', component: AcordMessagesComponent, pathMatch: 'full' },

];

// @NgModule({  imports: [
//     CommonModule,
//     RouterModule.forRoot(routes,
//       {
//         enableTracing: false,
//         useHash: true,
//         initialNavigation: isInIframe() ? 'disabled' : undefined // <-THIS
//       })],
//   exports: [
//     RouterModule
//   ],
//   declarations: []
// })
// export class AppRoutingModule { }

// export function isInIframe() {
//   return window !== window.parent && !window.opener;
// }
const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  // scrollOffset: [0, 64],
};

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, routerOptions)
  ],
  exports: [
    RouterModule
  ],
  declarations: []
})
export class AppRoutingModule { }
