<div class="card">
    <div class="card-header">
        <div class="d-flex bd-highlight">
            <div class="p-2 flex-fill bd-highlight">
              <h4>Upload file(s)</h4>
            </div>
            <div class="p-2  justify-content-end">        
              <button class="btn btn-primary" (click)="onClose()" >X</button>        
            </div>
          </div>
    </div>
    <div class="card-body">
        <div fxFlex fxLayout fxLayoutAlign="center" fxHide.xs>
            <table class= "uploadtable">                
                <tr>
                    <td>
                        <div>
                            <mat-label>Copy document path to clipboard </mat-label>                     
                            <button class="btn btn-primary" (click)="onCopyPath()">Copy</button>
                        </div>  
                        <div class="uploadfilecontainer" (click)="fileInput.click()" appDragDrop (onFileDropped)="onAddFiles($event)">
                            <input hidden type="file" #fileInput (change)="onAddFiles($event.target.files)" multiple>    
                            <br/><br/><br/><h5 class="uploadh5">Drop files here</h5>
                        </div>
                        <div  class="files-list" *ngFor="let file of totalFiles;let i= index">
                            <table class= "files-table">                
                                <tr>                                    
                                    <td class="files-table-column1">{{ file.name }}</td>
                                    <td class="files-table-column2">{{ file.size | fileSize }}</td>
                                    <td><a (click)="deleteAttachment(i)">
                                        <mat-icon mat-list-icon>delete</mat-icon>
                                    </a></td>
                                </tr>
                            </table>                            
                        </div>
                    </td>
                </tr>
            </table>
        </div>        
    </div>    
    <div class="card-footer">                  
        <div class="btn-group">           
           <button class="btn btn-primary" (click)="onClose()">Close</button>
        </div>
    </div>
</div>
