export interface AcordMessage {
  id: number;
  guid: string;
  sentDate: Date;
  message : string;
  operation : string;
  messageStatus : string;
  technicalStatus : string;
  requestResponse : string;
  retrieveResponse : string;
  reference : string;
  workOrderReference : string;
  umr : string;
  technicianName : string;
  technicianEmail : string;
  requestId: number;
  details : string;
}

export interface DocumentDto {
  id: 0,
  fileReference: string,
  fileName: string,
  documentType: string,
  description: string,
  documentContent: string,
  createdBy: string,
  createdOn: string,
  requestId: number,
  documentID: string,
  isSelected: boolean,
  confidential: boolean
}

export interface AcordDto {
  isCancellation: boolean;
  message: string;
  messageStatus: string;
  technicalStatus: string;
  requestResponse: string;
  retrieveResponse: string;
  technicianEmail: string;
  id: number;
  guid: string;
  sentDate: Date;
  operation: string;
  status: string;
  tStatus: string;
  workOrderReference: string;
  technicianName: string;
  technicianTelephoneNumber: string;
  umr: string;
  requestId: number;
  isRef: boolean;
  isCancelled: boolean;
  tr: string;
  ucr: string;
  submissionType: string;
  requestSubmitType: string;
  xisTechnician: string;
  additionalInformation: string;
  slipType: string;
  deLink: string;
  processingRequired: string;
  bureau: string;
  classOfBusiness: string;
  createdBy: string;
}

export interface RequestDto {
  id: 0,
  telephone: string,
  email: string,
  documentPath: string,
  contractType: string,
  contractNature: string,
  classOfBusiness: string,
  technicianName: string,
  businessType: string,
  umr: string,
  xisTechnician: string,
  delink: string,
  submissionType: string,
  groupReference: string,
  groupNumber: string,
  simChecked: boolean,
  ucr: string,
  tr: string,
  departmentType: string,
  additionalInformation: string,
  bureau: string,
  processingRequired: string,
  requestId: number,
  creationDate: string,
  workOrderRef: string,
  slipType: string,
  documents: DocumentDto [],  
  coverType: string,
  isSent: boolean,
  fullPath: string,
  documentType: string,
  accountTransactionType: string,
  senderIdentification: string
  companyBrokerId: number,
  createdBy: string,
  processingOption: string,
  umrDocuments: DocumentDto []
}

export interface UploadFileDto
{
  files: any [],
  fileName: string,
  umr: string,
  name: string
}

export interface CompanyBrokerDto
{
  id: number,
  email: string
}