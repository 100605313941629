import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit,EventEmitter, Output, Input } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AuthService } from '../../../Authentication/auth.service';


import { Client } from '@microsoft/microsoft-graph-client';
import * as MicrosoftGraph from '@microsoft/microsoft-graph-types';
import { AlertsService } from '../../../Authentication/alerts.service';
import { environment } from '../../../../../environments/environment';
import { User } from '../../../code/user';
import { HttpClient } from '@angular/common/http';

const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';
const GRAPH = 'https://graph.microsoft.com/v1.0';
type ProfileType = {
  givenName?: string,
  displayName?: string,
  mail? : string,
  surname?: string,
  userPrincipalName?: string,
  id?: string,
  group?: string
};



@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss']
})

export class NavigationBarComponent implements OnInit {
  profile!: ProfileType;
  group: any;
  public user: User;
  @Output() toggleSidenav = new EventEmitter<boolean>();
  @Output() logoutClicked = new EventEmitter();
  @Input() displayname : string;
  public displaynames : string;
  @Input() userrole : string;
  @Input()  usercompany: string;
  @Input() useremail: string;
  private returnUrl = '/';
  public groupNames: string[] = [];
  public graphUser : MicrosoftGraph.User;

  constructor( private authService: MsalService, private http: HttpClient) {
    this.getProfile();
  }

  getAccessTokenAndCallGraphAPI(){

    this.authService.instance.acquireTokenSilent({
      scopes: ['group.Read.All']
    }).then(result=>{
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json',
          Authorization: 'Bearer '+result.accessToken
        })}

       this.http.get(GRAPH+"/groups?$select=id,displayName",httpOptions).toPromise().then(result=>
      {
       // console.log(result)
        let g = result
        console.log(g)

      });
    })
    return this.profile.group
  }

  getProfile() {
    this.http.get(GRAPH_ENDPOINT)
      .subscribe(profile => {
        this.profile = profile;

          this.authService.instance.acquireTokenSilent({
            scopes: ['group.Read.All']
          }).then(result=>{
            const httpOptions = {
              headers: new HttpHeaders({
                'Content-Type':  'application/json',
                Authorization: 'Bearer '+result.accessToken
              })}

              this.http.get("https://graph.microsoft.com/v1.0/users/"+this.profile.id+"/memberOf/microsoft.graph.group?$select=displayName,id&",httpOptions).subscribe(data => {
                let g = data;
                this.group = data
                let groupsstring = JSON.parse(JSON.stringify(data));
                this.groupNames = [];
                (groupsstring.value as any[])
                  .filter(g => (g.displayName as string).length === 3)
                  groupsstring.value.forEach(g => {
                  this.groupNames.push(g.displayName)
                });
              });
          });
      });
    }

  getGroup () : string {
    return this.groupNames.toString();
  }

  getName () : string {
    if (this.authService.instance.getActiveAccount() == null) {

      return 'unknown'
    }

    return this.profile ? this.profile.displayName : 'unknown'
  }

  getEmail () : string {
    if (this.authService.instance.getActiveAccount() == null) {
      return 'unknown'
    }

    return this.profile ? this.profile.mail : 'unknown'
  }


 ngOnInit(){
 }

  onLogout() {
    this.logoutClicked.emit("logout");
  }

  goToGPM() {
    window.location.href = "https://gpmdev.co.uk";
  }
}
