import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from  '@angular/material/dialog';
import { ClipboardService } from 'ngx-clipboard';
import { DocumentService } from '../../../services/document.service';
import { UploadFileDto } from '../../code/InfocusInterfaces'

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css']
})
export class FileUploadComponent implements OnInit {
  //isFilesAdded: boolean;
  totalFiles: File [];
  currentFiles: File [];
  //progress: number;
  uploadFileDto: UploadFileDto;  
  
  constructor(private  dialogRef:  MatDialogRef<FileUploadComponent>, 
              @Inject(MAT_DIALOG_DATA) public  dialogData:  any,
              private _clipboardService: ClipboardService,
              private _documentService: DocumentService) {
    //this.isFilesAdded = false;
    this.totalFiles = [];
    this.currentFiles = [];
    this.uploadFileDto = {
      files: [],
      fileName: '',
      umr: '',
      name: ''
    };                  
  }

  ngOnInit(): void {
  }

  onCopyPath() {
    this._clipboardService.copyFromContent(this.dialogData.path)
  }

  onAddFiles(event) { 
    this.currentFiles = [];
    
    for (let index = 0; index < event.length; index++) {
      const element = event[index];
      this.totalFiles.push(element);
      this.currentFiles.push(element);
    }
    if(this.dialogData.from == 'ECF') {
      this.uploadECFFiles();      
    } else if(this.dialogData.from == 'WorkOrder'){
      this.uploadWorkOrderFiles();
    }

  }

  deleteAttachment(index) {
    if(this.dialogData.from == 'ECF') {
      this.removeECFFile(index);
    }else if(this.dialogData.from == 'WorkOrder'){
      this.removeWorkOrderFile(index);
    }    
  }

  onClose() {
    /* if(this.totalFiles.length > 0) {
      this.isFilesAdded = true;
    } */
    this.dialogRef.close();
  }

// ECF Methods
  uploadECFFiles() {
    const formData = new FormData();
    Array.from(this.currentFiles).map((file, index) => {
      return formData.append('file'+index, file, file.name);
    }); 
    formData.append('UMR', this.dialogData.umr);
    formData.append('Name', this.dialogData.name);

    this._documentService.UploadECFDocuments(formData).subscribe((data: number) => {
      //alert('Response data: ' + data);      
    })
  }

  removeECFFile(index: number) {
    const formData = new FormData();
    formData.append('FileName', this.totalFiles[index].name);
    formData.append('UMR', this.dialogData.umr);
    formData.append('Name', this.dialogData.name);

    this._documentService.DeleteECFDocument(formData).subscribe((data: number) => {
      //alert('Response data: ' + data);
      this.totalFiles.splice(index, 1);     
    })
  }

  // Submit Workorder Methods
  uploadWorkOrderFiles() {
    const formData = new FormData();
    Array.from(this.currentFiles).map((file, index) => {
      return formData.append('file'+index, file, file.name);
    }); 
    
    formData.append('UMR', this.dialogData.umr);
    formData.append('Name', this.dialogData.name);
    this._documentService.UploadDocuments(formData).subscribe((data: number) => {
    })
  }

  removeWorkOrderFile(index: number) {
    const formData = new FormData();
    formData.append('FileName', this.totalFiles[index].name);
    formData.append('UMR', this.dialogData.umr);
    formData.append('Name', this.dialogData.name);
    
    this._documentService.DeleteDocument(formData).subscribe((data: number) => {
      //alert('Response data: ' + data);
      this.totalFiles.splice(index, 1);     
    })
  }

}
