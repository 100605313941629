import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RequestService } from '../../../../services/request.service';
import { DocumentService } from '../../../../services/document.service';
import { RequestDto, DocumentDto } from '../../../../shared/code/InfocusInterfaces';

@Component({
  selector: 'request-detail',
  templateUrl: './request-detail.component.html',
  styleUrls: ['./request-detail.component.css'],
})
export class RequestDetailComponent implements OnInit {
  title:string;  
  requestId: number;
  requestDetailForm: FormGroup;
  errorMessage: any;
  requestDocs: DocumentDto[];

  constructor(private _fb: FormBuilder, 
              private __avRoute: ActivatedRoute,
              private _router: Router, 
              private _requestService: RequestService,
              private _documentService: DocumentService) {
    if(this.__avRoute.snapshot.params["id"]){
      this.requestId = this.__avRoute.snapshot.params["id"];
    }
    this.requestDetailForm = this._fb.group({
      id: 0,
      telephone: ['', []],
      email: ['', []],
      documentPath: ['',[]],
      contractType: ['', []],
      contractNature: ['', []],
      classOfBusiness: ['',[]],
      technicianName: ['', []],
      businessType: ['', []],
      umr: ['',[]],
      xisTechnician: ['', []],
      delink: ['', []],
      submissionType: ['',[]],
      groupReference: ['', []],
      groupNumber: [0,[]],
      simChecked: ['', []],
      ucr: ['', []],
      tr: ['',[]],
      departmentType: ['', []],
      additionalInformation: ['', []],
      bureau: ['',[]],
      processingRequired: ['', []],
      requestId: ['', []],
      creationDate: ['',[]],
      workOrderRef: ['', []],
      slipType: ['', []],
      coverType: ['',[]],
      isSent: [false, []],
      fullPath: ['', []],      
      documentType: [0,[]],
      accountTransactionType: ['',[]],
      senderIdentification: ['', []],
      documents: [],
      createdBy: ['', []],
      processingOption: ['',[]],
      umrDocuments: [],
    })    
  }

  ngOnInit(): void {
    if(this.requestId > 0) {
    this.title = 'Request Message';
    this.requestDocs = [];
    setTimeout(() => {
      this.getRequestMessage();
      this.getAttachedRequestDocs();
    }, 1000);
   
    }
  }

  getRequestMessage() {
    console.log("Request Data before service call" );
    console.log(this.requestId );
    this._requestService.getRequestById(this.requestId).subscribe((data: RequestDto) => 
    this.requestDetailForm.setValue({...data}), error => this.errorMessage = error);
  }

  getAttachedRequestDocs() {
    this._documentService.GetAttachedRequestDocs(this.requestId).subscribe((data: DocumentDto[]) => {
      this.requestDocs = data;
    })   
  }

  onBackToList() {
    this._router.navigate(['/requests']);
  }
}