<div class="jumbotron">
    <div class="card">
        <div class= "card-header">
        <div class="d-flex bd-highlight">
            <div class="p-2 flex-fill bd-highlight">
                <h3>Details</h3>
                <p>{{ title }}</p>
            </div>
        </div>
        </div>
        <div class="card-body">
            <form [formGroup]="acordMessageDetailForm" >
                <div class="container-fluid">
                    <table class="table">
                        <tbody>
                            <tr class="d-flex">
                                <th class="th-details col-1">UUID:</th>
                                <td class="col-11">{{acordMessageDetailForm.controls.guid.value}}</td>
                            </tr>
                            <tr class="d-flex">
                                <th class="th-details col-1">Sent Date:</th>
                                <td class="col-11">{{acordMessageDetailForm.controls.sentDate.value}}</td>
                            </tr>
                            <tr class="d-flex">
                                <th class="th-details col-1">SOAP Request:</th>
                                <td class="col-11">
                                    <textarea readonly class="form-control" rows="10" placeholder="" formControlName="message"></textarea>
                                </td>                        
                            </tr>
                            <tr class="d-flex">
                                <th class="th-details col-1">Submission Type:</th>
                                <td class="col-11">{{acordMessageDetailForm.controls.operation.value}}</td>
                            </tr>
                            <tr class="d-flex">
                                <th class="th-details col-1">Status:</th>
                                <td class="col-11">{{acordMessageDetailForm.controls.messageStatus.value}}</td>
                            </tr>
                            <tr class="d-flex">
                                <th class="th-details col-1">Technical Status:</th>
                                <td class="col-11">{{acordMessageDetailForm.controls.technicalStatus.value}}</td>
                            </tr>
                            <tr class="d-flex">
                                <th class="th-details col-1">Response:</th>                        
                                <td class="col-11">
                                    <textarea readonly class="form-control" rows="10" placeholder="" formControlName="requestResponse"></textarea>
                                </td>                        
                            </tr>
                            <tr class="d-flex">
                                <th class="th-details col-1">Retrieve Response:</th>
                                <td class="col-11">
                                    <textarea readonly class="form-control" rows="10" placeholder="" formControlName="retrieveResponse"></textarea>
                                </td>                        
                            </tr>
                            <tr class="d-flex">
                                <th class="th-details col-1">Reference:</th>
                                <td class="col-11">{{acordMessageDetailForm.controls.reference.value}}</td>
                            </tr>
                            <tr class="d-flex">
                                <th class="th-details col-1">Work Package Reference:</th>
                                <td class="col-11">{{acordMessageDetailForm.controls.workOrderReference.value}}</td>
                            </tr>                    
                        </tbody>
                    </table>
                </div>
            </form>
        </div> 
        <div class="card-footer">            
            <div class="btn-group">
            <button class="btn btn-primary" (click)="onBackToList()">Back to List</button>
            </div>
        </div>
    </div>
</div>